import React, { useContext } from "react";
import { UserContext } from '../../Context/UserContext'


function EventForm(props) {
    const { currentUser } = useContext(UserContext);

    return (
        <form name={props.formName} onSubmit={props.formOnSubmit}>
            <div className="form-row">
                <div className="form-group col-11 mx-auto">
                    <label for="appDate">Event Date :</label>
                    <input type="text" name="appDate" className="form-control " disabled defaultValue={props.appDate} required></input>
                </div>

                <div className="form-group col-11 pl-3 mx-auto">
                    <label for="LastName">Event Time :</label>
                    {/* <input type="text" name="lastName" placeholder="Event Date" className="form-control" required defaultValue={props.lastName}  ></input> */}
                    <select name="appTime" id="appTime" className="form-control" aria-label="Default select example" required>
                        <option selected value={props.appTime}>{props.appTime}</option>
                        {
                            props.availableSlots.map(slot => {
                                if (props.appTime != slot)
                                    return <option value={slot}>{slot}</option>
                            })
                        }
                    </select>
                </div>

                <div className="form-group col-11 pl-3 mx-auto">
                    {/* <label for="employee">Location :</label>
                    <select name="employee" id="employee" className="form-control" aria-label="Default select example" required disabled>
                        <option selected value={props.employee.employeeId}>{props.employee.firstName} {props.employee.lastName}</option>
                    </select> */}
                    <label for="employee">Location: </label>
                    <select name="employee" id="employee" className="form-control" aria-label="Default select example" required disabled={props.employeeSelected ? 'true' : null}>
                        <option value=''>Choose Location</option>
                        {
                            props.employeeList.map(employee => {
                                if (props.employeeSelected == employee._id) {
                                    return <option value={employee._id} selected>{employee.userId.firstName} {employee.userId.lastName}</option>
                                }
                                else {
                                    return <option value={employee._id} >{employee.userId.firstName} {employee.userId.lastName}</option>
                                }

                            })
                        }
                    </select>
                </div>
                <div className="form-group col-11 pl-3 mx-auto">
                    <label for="customer">Customer :</label>
                    <select name="customer" className="form-control" disabled={currentUser.userType == "Customer" ? true : null}>
                        {props.customerList
                            .map((customer, i) => {
                                if (props.customerSelected == customer._id) {
                                    return <option key={i} value={customer._id} selected>{customer.userId.firstName} {customer.userId.lastName}</option>
                                }
                                else {
                                    return <option key={i} value={customer._id}>{customer.userId.firstName} {customer.userId.lastName}</option>
                                }
                            })}
                    </select>
                </div>
            </div>
            <input type="hidden" name="id" defaultValue={props.eventId} />
            <div className="text-center">
                <input type="submit" className="btn btn-primary my-2 mx-4  col-4 " id="customBtn" value="Submit"></input>
            </div>


        </form>
    );
}

export default EventForm;