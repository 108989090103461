import styles from './Dashboard.module.css';
import { React, useState, useEffect, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import axios from "axios";
import { NavLink } from 'react-router-dom';
import moment from "moment";
import { UserContext } from '../../Context/UserContext';

export default function CorporateDashboard() {
    const [customerCount, setCustomerCount] = useState(0);
    const [appsTodayCount, setAppsTodayCount] = useState(0);
    const [pendingAppsTodayCount, setPendingAppsTodayCount] = useState(0);
    const [bookedAppointments, setBookedAppointments] = useState([]);
    const [newCustomers, setNewCustomers] = useState([]);
    const { currentUser } = useContext(UserContext);

    const getCounts = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            const customerResponse = await axios.post(`${baseApi}/count/users`, { userType: "Customer" }, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setCustomerCount(customerResponse.data.count || 0);

            const appointmentsResponse = await axios.get(`${baseApi}/count/appointments`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setAppsTodayCount(appointmentsResponse.data.totalAppointments || 0);
            setPendingAppsTodayCount(appointmentsResponse.data.pendingAppointments || 0);

            const newCustomerResponse = await axios.get(`${baseApi}/customers/new`, {
                headers: { authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setNewCustomers(newCustomerResponse.data.customers || []);
        } catch (error) {
            console.error("Error fetching counts:", error);
        }
    };

    useEffect(() => {
        getCounts();
    }, []);

    return (
        <Box className={styles.dashboardBody} component="main" sx={{ flexGrow: 1, p: 3 }}>
            {/* Banner */}
            <div id={styles.corporateWelcome}>
				<div className='text-white'>
					<h3 >Welcome!</h3>
					<br/>
					<h4>{currentUser.firstName} {currentUser.lastName}</h4>
					<br/>
					<div class={styles.horizontalLine}></div>
					At Docotela, we believe that every patient deserves the highest quality care possible. 
					<br/>
					Our commitment to excellence in healthcare is matched only by our compassion for those we serve.
		    </div>
			</div>

            {/* Stats Section */}
            <div className={styles.statCardGrid}>
                <div className={styles.statCard}>
                    <div className={styles.dashWidget}>
                        <span className={styles.dashWidgetBg1}>
                            <i className="fa fa-users" aria-hidden="true"></i>
                        </span>
                        <div className={styles.dashWidgetInfo}>
                            <h3>{customerCount}</h3>
                            <span>Customers</span>
                        </div>
                    </div>
                </div>
                <div className={styles.statCard}>
                    <div className={styles.dashWidget}>
                        <span className={styles.dashWidgetBg2}>
                            <i className="fa fa-calendar" aria-hidden="true"></i>
                        </span>
                        <div className={styles.dashWidgetInfo}>
                            <h3>{appsTodayCount}</h3>
                            <span>Appointments Today</span>
                        </div>
                    </div>
                </div>
                <div className={styles.statCard}>
                    <div className={styles.dashWidget}>
                        <span className={styles.dashWidgetBg3}>
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                        </span>
                        <div className={styles.dashWidgetInfo}>
                            <h3>{pendingAppsTodayCount}</h3>
                            <span>Pending Appointments</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}
            <div className="row">
                {/* Upcoming Appointments */}
                <div className="col-12 col-lg-8 col-xl-8">
                    <div className="card appointment-panel">
                        <div className="card-header">
                            <h4 className="card-title">Upcoming Appointments</h4>
                            <NavLink to="/appointments" className="btn btn-primary float-end">View all</NavLink>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <tbody>
                                        {bookedAppointments.length > 0 ? (
                                            bookedAppointments.map((apt) => (
                                                <tr key={apt.id}>
                                                    <td>
                                                        <h5>{apt.patientId.userId.firstName} {apt.patientId.userId.lastName}</h5>
                                                    </td>
                                                    <td>Dr. {apt.doctorId.userId.firstName} {apt.doctorId.userId.lastName}</td>
                                                    <td>{apt.appointmentTime}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">
                                                    No appointments scheduled for today.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* New Customers */}
                <div className="col-12 col-lg-4 col-xl-4">
                    <div className="card member-panel">
                        <div className="card-header bg-white">
                            <h4 className="card-title">New Customers</h4>
                        </div>
                        <div className="card-body">
                            <ul className="contact-list">
                                {newCustomers.length > 0 ? (
                                    newCustomers.map((customer) => (
                                        <li key={customer.id}>
                                            <div className="contact-info">
                                                <span className="contact-name">{customer.firstName} {customer.lastName}</span>
                                                <span className="contact-date">{customer.createdAt}</span>
                                            </div>
                                        </li>
                                    ))
                                ) : (
                                    <li className="text-center">No new customers</li>
                                )}
                            </ul>
                        </div>
                        <div className="card-footer text-center bg-white">
                            <NavLink to="/customers" className="text-muted">View all Customers</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
}
