import React, { useState } from 'react';
import { Box, Typography, Divider, Modal, TextField, Button } from '@mui/material';

const AppointmentModal = ({ isOpen, onClose, appointment }) => {
  const [notes, setNotes] = useState('');
  const [noteThread, setNoteThread] = useState([]);
  const patient = appointment?.patientId?.userId || {};
  const doctor = appointment?.doctorId?.userId || {};

  if (!appointment) {
    return null;
  }

  const handleAddNote = () => {
    const newNote = {
      doctor: 'Dr. Example', // Replace with actual doctor information
      timestamp: new Date().toLocaleString(),
      content: notes,
    };
    setNoteThread([...noteThread, newNote]);
    setNotes('');
  };

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ overflow: 'auto' }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          {/* Patient Details */}
          <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Typography variant="h6">Patient Details</Typography>
            <Divider />
            <Typography>Name: {appointment?.patient.name || ""}</Typography>
            <Typography>Surname: {appointment?.patient.surname || ""}</Typography>
            <Typography>Contact Number: {appointment?.patient.contactNumber || ""}</Typography>
            <Typography>Email: {appointment?.patient.email || ""}</Typography>
            <Typography>Address: {appointment?.patient.address || ""}</Typography>
            <Typography>ID Number: {appointment?.patient.idNumber || ""}</Typography>
          </Box>

          {/* Notes and Prescriptions */}
          <Box sx={{ width: '25%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box>
              <Typography variant="h6">Past Notes</Typography>
              <Divider />
              <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', p: 1 }}>
                {appointment?.notes?.map((note, index) => (
                  <Typography key={index}>{note}</Typography>
                ))}
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Past Prescriptions</Typography>
              <Divider />
              <Box sx={{ maxHeight: '150px', overflowY: 'auto', border: '1px solid #ccc', p: 1 }}>
                {appointment?.prescriptions?.map((prescription, index) => (
                  <Typography key={index}>{prescription}</Typography>
                ))}
              </Box>
            </Box>
          </Box>

          {/* Video Frame and Conditions */}
          <Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box sx={{ height: '200px', border: '1px solid #ccc' }}>Video Frame (Twilio Video)</Box>
            <Box>
              <Typography variant="h6">Past Conditions</Typography>
              <Divider />
              <Box sx={{ maxHeight: '100px', overflowY: 'auto', border: '1px solid #ccc', p: 1 }}>
                {appointment?.conditions?.map((condition, index) => (
                  <Typography key={index}>{condition}</Typography>
                ))}
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Past Medications</Typography>
              <Divider />
              <Box sx={{ maxHeight: '100px', overflowY: 'auto', border: '1px solid #ccc', p: 1 }}>
                {appointment?.medications?.map((medication, index) => (
                  <Typography key={index}>{medication}</Typography>
                ))}
              </Box>
            </Box>
          </Box>
        </div>

        {/* Rich Text Editor for Notes */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Add Notes</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter your notes here..."
          />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleAddNote}
          >
            Add Note
          </Button>
        </Box>

        {/* Notes Thread */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Notes Thread</Typography>
          <Divider />
          <Box sx={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #ccc', p: 1 }}>
            {noteThread.map((note, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  {note.doctor} - {note.timestamp}
                </Typography>
                <Typography>{note.content}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AppointmentModal;
