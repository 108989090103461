import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import OrderTable from '../MUITable/OrderTable';


function OrderList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [orders, setorder] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };


    useEffect(() => {
        getorders();
    }, []
    );

    const getorders = async () => {    
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/orders`, {
            params: {
                name: name
            }
        });
        setorder(response.data);
    };

    const deleteOrder = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/orders/${id}`);
            getorders();
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }


    };


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title fw-bold">Order</h4>
                        </div>
                        {/* <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/account/orders/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Order
                            </Link>
                        </div> */}
                    </div>
                    <form action="/account/orders" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating ">

                                    <input type="text" name="name" className="form-control" placeholder='Order Id or Name' />
                                    <label className="focus-label">Order Id or Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <OrderTable orderList={orders} deleteOrder={deleteOrder} />
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Company</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order, index) => (
                                            <tr key={order._id}>
                                                <td
                                                >{index + 1}</td>
                                                <td>{order.company}</td>
                                                <td>{order.name}</td>
                                                <td>{order.description}</td>
                                                <td>{order.price}</td>
                                                <td className="text-right">
                                                    <Link to={`/orders/edit/${order._id}`} className="btn btn-warning is-info is-small m-r-2">Edit</Link> &nbsp;
                                                    <button onClick={() => deleteOrder(order._id)} className="btn btn-danger is-danger is-small m-l-5">Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add order"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default OrderList;
