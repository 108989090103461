import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ProductTable from '../MUITable/ProductTable';
import ToastService from '../../Service/toast.service';

function ProductList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [products, setproduct] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };


    useEffect(() => {
        getproducts();
    }, []
    );

    const getproducts = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/products`, {
            params: {
                name: name
            }
        });
        setproduct(response.data);
    };

    const deleteProduct = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/products/${id}`);
            ToastService.success("Successfully deleted product.")
            getproducts();
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }


    };


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Product</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/account/products/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Product
                            </Link>
                        </div>
                    </div>
                    <form action="/account/products" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating ">

                                    <input type="text" name="name" className="form-control" placeholder='Product Name' />
                                    <label className="focus-label">Product Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <ProductTable productList={products} deleteProduct={deleteProduct} />
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Company</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Price</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={product._id}>
                                                <td
                                                >{index + 1}</td>
                                                <td>{product.company}</td>
                                                <td>{product.name}</td>
                                                <td>{product.description}</td>
                                                <td>{product.price}</td>
                                                <td className="text-right">
                                                    <Link to={`/products/edit/${product._id}`} className="btn btn-warning is-info is-small m-r-2">Edit</Link> &nbsp;
                                                    <button onClick={() => deleteProduct(product._id)} className="btn btn-danger is-danger is-small m-l-5">Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add product"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default ProductList;
