import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from "axios";
import Box from '@mui/material/Box';

function Edittransaction() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [company, setCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [name, setName] = useState('');
  const [customerDetails, setCustomerDetails] = useState({});
  const [customers, setCustomers] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  
  const handleDialogueOpen = () => setErrorDialogueBoxOpen(true);
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  useEffect(() => {
    getTransactionById();
    fetchCompanies();
    fetchCustomers();
  }, []);

  const fetchCompanies = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/corporates`);
    setCompanies(response.data);
  };

  const fetchCustomers = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/customers`);
    setCustomers(response.data);
  };

  const getTransactionById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/transactions/${id}`);
    const data = response.data;

    setCompany(data.company);
    setName(data.name);
    setCustomerDetails({
      contactNumber: data.contactNumber,
      email: data.email,
      address: data.address,
    });
    setTableData(data.items || []);
    calculateTotals(data.items || []);
  };

  const updatetransaction = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      await axios.patch(`${baseApi}/transactions/${id}`, {
        company,
        name,
        items: tableData,
      });
      navigate("/account/transactions");
    } catch (error) {
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };

  const calculateTotals = (items) => {
    const subtotal = items.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const vat = subtotal * 0.15; // Assuming 15% VAT
    const grandTotal = subtotal + vat;

    setSubtotal(subtotal);
    setVat(vat);
    setGrandTotal(grandTotal);
  };

  const handleTableChange = (index, key, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][key] = value;
    setTableData(updatedTableData);
    calculateTotals(updatedTableData);
  };

  const handleCustomerChange = (customerId) => {
    const selectedCustomer = customers.find((customer) => customer._id === customerId);
    if (selectedCustomer) {
      setName(`${selectedCustomer.firstName} ${selectedCustomer.lastName}`);
      setCustomerDetails({
        contactNumber: selectedCustomer.contactNumber,
        email: selectedCustomer.email,
        address: selectedCustomer.address,
      });
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">

          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="page-title fw-bold">Edit Transaction - {id}</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form id="edittransactionForm" name='edittransactionForm' onSubmit={updatetransaction}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Company <span className="text-danger">*</span></label>
                        <select
                          className="form-control"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        >
                          <option value="">Select a Company</option>
                          {companies.map((corp) => (
                            <option key={corp._id} value={corp.name}>{corp.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Name <span className="text-danger">*</span></label>
                        <select
                          className="form-control"
                          value={name}
                          onChange={(e) => handleCustomerChange(e.target.value)}
                        >
                          <option value="">Select a Customer</option>
                          {customers.map((cust) => (
                            <option key={cust._id} value={cust._id}>
                              {cust.firstName} {cust.lastName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          className="form-control"
                          type="text"
                          value={customerDetails.contactNumber || ''}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          className="form-control"
                          type="email"
                          value={customerDetails.email || ''}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address</label>
                        <textarea
                          className="form-control"
                          value={customerDetails.address || ''}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Package Name</th>
                          <th>Package Price</th>
                          <th>Subscription</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                className="form-control"
                                value={item.name}
                                onChange={(e) => handleTableChange(index, 'name', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                value={item.price}
                                onChange={(e) => handleTableChange(index, 'price', parseFloat(e.target.value))}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                value={item.subscription}
                                onChange={(e) => handleTableChange(index, 'subscription', e.target.value)}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="number"
                                value={item.quantity}
                                onChange={(e) => handleTableChange(index, 'quantity', parseInt(e.target.value))}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 text-right">
                      <h5>Subtotal: {subtotal.toFixed(2)}</h5>
                      <h5>VAT (15%): {vat.toFixed(2)}</h5>
                      <h5>Grand Total: {grandTotal.toFixed(2)}</h5>
                    </div>
                  </div>
                  <div className="m-t-20 text-left">
                    <button type="submit" className="btn btn-primary submit-btn">Update Transaction</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Transaction"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default Edittransaction;
