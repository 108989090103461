import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ToastService from "../../Service/toast.service";

const ContactInfoScreen = () => {
    const [token, setToken] = useState("");
    const [entities, setEntities] = useState([]);
    const [entityDetails, setEntityDetails] = useState({});
    const [selectedEntity, setSelectedEntity] = useState(null); // For modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const authenticate = async () => {
        try {
            const response = await axios.post(
                "https://integrationapi.test.services-avantedge.co.za/api/Authenticate",
                {},
                {
                    headers: {
                        username: "RC_DOC_SANDBOX",
                        password: "amazingly likeness alike flatware",
                    },
                }
            );
            setToken(response.data.access_token);
            return response.data.access_token;
        } catch (err) {
            setError("Authentication failed");
            throw new Error(err.response?.data?.message || "Authentication failed");
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        setError("");
        try {
            const authToken = token || (await authenticate());
            setToken(authToken);

            const response = await axios.get(
                "https://integrationapi.test.services-avantedge.co.za/api/GetEntityGroup",
                {
                    headers: { Authorization: `Bearer ${authToken}` },
                }
            );

            ToastService.success("Entities successfully retrieved.");
            const entities = response.data.entities || [];
            setEntities(entities);

            // Fetch details and expand rows for all entities
            for (const entity of entities) {
                await fetchEntityDetails(entity.code);
                setEntityDetails((prevDetails) => ({
                    ...prevDetails,
                    [entity.code]: {
                        ...prevDetails[entity.code],
                        expanded: true, // Automatically expand the row
                    },
                }));
            }
        } catch (err) {
            setError("An error occurred while fetching entities.");
        } finally {
            setLoading(false);
        }
    };


    const fetchEntityDetails = async (entityCode) => {
        try {
            const authToken = token || (await authenticate());
            setToken(authToken);

            const requestBody = [
                {
                    campaignCode: entityCode,
                },
            ];

            const response = await axios.post(
                "https://integrationapi.test.services-avantedge.co.za/api/GetEntity",
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            setEntityDetails((prevDetails) => ({
                ...prevDetails,
                [entityCode]: response.data || {},
            }));
        } catch (err) {
            setEntityDetails((prevDetails) => ({
                ...prevDetails,
                [entityCode]: `Error: ${err.message}`,
            }));
        }
    };

    const toggleRowExpansion = (entityCode) => {
        if (!entityDetails[entityCode]) {
            fetchEntityDetails(entityCode);
        }

        setEntityDetails((prevDetails) => ({
            ...prevDetails,
            [entityCode]: {
                ...prevDetails[entityCode],
                expanded: !prevDetails[entityCode]?.expanded,
            },
        }));
    };

    const openModal = (entity) => {
        setSelectedEntity(entity);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedEntity(null);
        setIsModalOpen(false);
    };

    const handleConvertToCustomer = (entity) => {
        alert(`Converting ${entity.entityReferenceNumber} to a customer.`);
        ToastService(`Converted ${entity.entityReferenceNumber} to a customer`)
        // Add your logic here for the "Convert to customer" action.
    };

    const handleCheckMembership = (entity) => {
        alert(`Checking membership for ${entity.entityReferenceNumber}.`);
        // Add your logic here for the "Check Membership" action.
    };

    const renderEntityDetailsTable = (details) => {
        if (typeof details === "string") {
            return <Alert severity="error">{details}</Alert>;
        }

        if (!details || Object.keys(details).length === 0) {
            return <p>No details available for this entity.</p>;
        }

        if (details.entities) {
            return (
                <Table size="medium" aria-label="entity details">
                    <TableHead>
                        <TableRow sx={{ fontWeight: 'bold' }}>
                            <TableCell><b>Reference Number</b></TableCell>
                            <TableCell><b>Account Number</b></TableCell>
                            <TableCell><b>First Name</b></TableCell>
                            <TableCell><b>Surname</b></TableCell>
                            <TableCell><b>Email</b></TableCell>
                            <TableCell><b>Actions</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {details.entities.map((entity, index) => (
                            <TableRow
                                key={index}
                                style={{ cursor: "pointer" }}
                            >
                                <TableCell>{entity.entityReferenceNumber || "N/A"}</TableCell>
                                <TableCell>{entity.entityAccountNumber || "N/A"}</TableCell>
                                <TableCell>{entity.firstName || "N/A"}</TableCell>
                                <TableCell>{entity.surname || "N/A"}</TableCell>
                                <TableCell>{entity.email || "N/A"}</TableCell>
                                <TableCell>
                                    <Tooltip title="View all details">
                                        <IconButton onClick={() => openModal(entity)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Convert to customer">
                                        <IconButton onClick={() => handleConvertToCustomer(entity)}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Check membership">
                                        <IconButton onClick={() => handleCheckMembership(entity)}>
                                            <CheckBoxIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        }

        return (
            <Table size="small" aria-label="entity details">
                <TableBody>
                    <TableRow>
                        <TableCell>No entity data to display</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    };

    useEffect(() => {
        fetchEntities();
    }, []); // Empty dependency array to run only once on mount

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Entity Management</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <button onClick={fetchEntities} className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i>&nbsp;Fetch Entities
                            </button>
                        </div>
                    </div>
                    {entities.length > 0 && (
                        <TableContainer component={Paper} className="mt-4">
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ fontWeight: 'bold' }}>
                                        <TableCell />
                                        <TableCell><b>Code</b></TableCell>
                                        <TableCell><b>Description</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {entities.map((entity, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => toggleRowExpansion(entity.code)}
                                                    >
                                                        {entityDetails[entity.code]?.expanded ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{entity.code}</TableCell>
                                                <TableCell>{entity.description}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3} style={{ padding: 0 }}>
                                                    <Collapse
                                                        in={entityDetails[entity.code]?.expanded}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <Box margin={1} style={{ padding: "20px" }}>
                                                            <h4>Entity Details</h4>
                                                            {renderEntityDetailsTable(entityDetails[entity.code])}
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    {/* Modal */}
                    <Modal open={isModalOpen} onClose={closeModal}>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                boxShadow: 24,
                                p: 4,
                                maxWidth: 900,
                                width: "90%",
                                overflowY: "auto",
                                maxHeight: "90vh",
                                borderRadius: "8px",
                            }}
                        >
                            <Typography variant="h6" component="h2" gutterBottom>
                                Entity Details
                            </Typography>
                            {selectedEntity ? (
                                <Grid container spacing={2}>
                                    {Object.entries(selectedEntity).map(([key, value]) => {
                                        if (key === "entityAdditionalFields" && Array.isArray(value)) {
                                            // Render additional fields as separate items
                                            return value.map((field, index) => (
                                                <Grid item xs={12} sm={6} md={4} key={`${key}-${index}`}>
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {field.key || "Additional Field"}
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {field.value || "N/A"}
                                                    </Typography>
                                                </Grid>
                                            ));
                                        }

                                        return (
                                            <Grid item xs={12} sm={6} md={4} key={key}>
                                                <Typography variant="subtitle2" gutterBottom>
                                                    {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {typeof value === "object" ? (
                                                        <pre style={{ margin: 0, whiteSpace: "pre-wrap" }}>
                                                            {JSON.stringify(value, null, 2)}
                                                        </pre>
                                                    ) : (
                                                        value || "N/A"
                                                    )}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            ) : (
                                <Typography variant="body2">No details available.</Typography>
                            )}
                        </Box>
                    </Modal>
                </div>
            </div>
        </Box>
    );
};

export default ContactInfoScreen;
