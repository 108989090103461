import React, { useEffect, useState } from 'react';
// import Header from '../Layout/Header';
// import Sidebar from '../Layout/Sidebar';
import Header from '../Layout/Header/Header';
import Sidebar from '../Layout/Sidebar/Sidebar';
import { Link, Outlet } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import CorporateTable from '../MUITable/CorporateTable';

function CorporateList() {
    const params = new URLSearchParams(window.location.search);
    const role = params.get('role');
    const name = params.get('name');
    const [corporates, setCorporate] = useState([]);
    const [searchrole, setRole] = useState([]);
    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    useEffect(() => {
        getCorporates();
    }, []
    );

    const getCorporates = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/corporates`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            },
            params: {
                role: role,
                name: name
            }
        });
        setCorporate(response.data);
    };

    const deleteCorporate = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        try {
            await axios.delete(`${baseApi}/corporates/${id}`, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            getCorporates();
        } catch (error) {
            setErrorList(error);
            handleDialogueOpen();
        }


    };


    return (

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title">Corporate</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/account/corporates/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Corporate
                            </Link>
                        </div>
                    </div>
                    <form action="/corporates" name="corporateFilter" >
                        <div className="row filter-row pb-4">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating ">
                                    <input name="name" type="text" id="empNameSearch" className="form-control" placeholder='Name' />
                                    <label htmlFor='empNameSearch'>Corporate Name</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <select name="role" className="form-select floating">
                                        <option value="Client">Client</option>
                                        <option value="Partner">Partner</option>
                                    </select>
                                    <label htmlFor='role' className="focus-label">Type</label>
                                </div>
                            </div>
                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <CorporateTable corporateList={corporates} deleteCorporate={deleteCorporate} />
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {corporates.map((corporate, index) => (
                                            <tr key={corporate._id}>
                                                <td>{index + 1}</td>
                                                <td>{corporate.firstName} {corporate.lastName}</td>
                                                <td>{corporate.email}</td>
                                                <td><span className="custom-badge status-green">{corporate.corporateType}</span></td>
                                                <td>
                                                    <Link
                                                        to={`/corporates/edit/${corporate._id}`}
                                                        className="btn btn-warning is-info is-small m-r-2"
                                                    >
                                                        <i className="fa fa-pencil m-r-5"></i> Edit
                                                    </Link>
                                                    <button
                                                        onClick={() => deleteCorporate(corporate._id)}
                                                        className="btn btn-danger is-danger is-small m-l-5"
                                                    >
                                                        <i className="fa fa-trash-o m-r-5"></i>  Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add Corporate"
                    ErrorList={errorList}
                />
            </div>
        </Box>


    )
}

export default CorporateList;
