import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from "axios";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ToastService from '../../Service/toast.service';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function EditPatient() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [userId, setUserId] = useState('');
  const [conditions, setConditions] = useState([]);
  const [newCondition, setNewCondition] = useState('');
  const [medications, setMedications] = useState([]);
  const [newMedication, setNewMedication] = useState({ name: '', startDate: '', inUse: false });
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]); 
  const [patientHistory, setPatientHistory] = useState([]);

  const [activityLog, setActivityLog] = useState([]);
  const [bookings, setBookings] = useState([]);

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const handleTabChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    getPatientById();
  }, []);

  const getPatientById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
  
    try {
      const response = await axios.get(`${baseApi}/patients/${id}`);
      const data = response.data;
  
      if (data && data.userId) {
        setFirstName(data.userId.firstName || '');
        setLastName(data.userId.lastName || '');
        setEmail(data.userId.email || '');
        setUsername(data.userId.username || '');
        setPassword(data.userId.password || '');
        setConfirmPassword(data.userId.password || '');
        setPhone(data.phone || '');
        setAddress(data.address || '');
        setUserId(data.userId._id || '');
        setGender(data.gender || '');
        setDOB(data.dob || '');
        setConditions(data.conditions || []);
        setMedications(data.medications || []);
      } else {
        throw new Error('Invalid patient data received');
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
      setErrorList([error.message || 'Failed to fetch patient data']);
      handleDialogueOpen();
    }
  };  

  const updatePatient = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      await axios.patch(`${baseApi}/patients/${id}`, {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        confirmPassword,
        address,
        gender,
        dob,
        conditions,
        medications,
        userId,
      });
      ToastService.success("Patient details updated successfully.")
      navigate("/account/patients");
    } catch (error) {
      console.log(error.response.data.errors);
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };

  const addCondition = () => {
    if (newCondition.trim() !== '') {
      setConditions([...conditions, newCondition.trim()]);
      setNewCondition('');
    }
  };

  const removeCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const addMedication = () => {
    if (newMedication.name.trim() !== '' && newMedication.startDate !== '') {
      setMedications([...medications, newMedication]);
      setNewMedication({ name: '', startDate: '', inUse: false });
    }
  };

  const removeMedication = (index) => {
    setMedications(medications.filter((_, i) => i !== index));
  };

  const fetchBookings = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/patient/${id}/bookings`);
    setBookings(response.data.bookings || []);
  };

  const fetchActivityLog = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/patient/${id}/activity`);
    setActivityLog(response.data.activity || []);
  };

  useEffect(() => {
    if (password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
          <Tabs value={value} onChange={handleTabChange} aria-label="Patient Tabs">
              <Tab label="Primary Details" />
              <Tab label="Patient History" />
              <Tab label="Bookings" />
              <Tab label="Activity" />
            </Tabs>

            <div className="row">
              <div className="col-lg-8">
              <TabPanel value={value} index={0}>
                <h3 className="page-title">Edit Patient</h3>
                <form id="editPatientForm" name="editPatientForm" onSubmit={updatePatient}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          required
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          required
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          name="address"
                          className="form-control"
                          type="text"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Gender</label>
                        <select
                          name="gender"
                          className="form-select"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Date of Birth</label>
                        <input
                          name="dob"
                          className="form-control"
                          type="date"
                          value={dob}
                          onChange={(e) => setDOB(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <button type="submit" className="btn btn-primary submit-btn">
                      Update Patient
                    </button>
                  </div>
                </form>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <h3>Patient History</h3>
                  {bookings.length > 0 ? (
                    <ul>
                      {patientHistory.map((history, index) => (
                        <li key={index}>
                          {history.date} - {history.status}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No patient history found.</p>
                  )}
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <h3>Bookings</h3>
                  {bookings.length > 0 ? (
                    <ul>
                      {bookings.map((booking, index) => (
                        <li key={index}>
                          {booking.date} - {booking.status}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No bookings found.</p>
                  )}
                </TabPanel>

                {/* Activity */}
                <TabPanel value={value} index={3}>
                  <h3>Activity</h3>
                  {activityLog.length > 0 ? (
                    <ul>
                      {activityLog.map((activity, index) => (
                        <li key={index}>{activity}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No activity recorded.</p>
                  )}
                </TabPanel>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <h4>Conditions</h4>
                  </div>
                  <div className="card-body">
                    <input
                      type="text"
                      className="form-control mb-2"
                      placeholder="Add condition"
                      value={newCondition}
                      onChange={(e) => setNewCondition(e.target.value)}
                    />
                    <button className="btn btn-primary btn-sm mb-2" onClick={addCondition}>
                      Add Condition
                    </button>
                    <ul className="list-group">
                      {conditions.map((condition, index) => (
                        <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                          {condition}
                          <button className="btn btn-danger btn-sm" onClick={() => removeCondition(index)}>
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-header">
                    <h4>Medications</h4>
                  </div>
                  <div className="card-body">
                    <div className="row mb-2">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Medicine Name"
                          value={newMedication.name}
                          onChange={(e) =>
                            setNewMedication({ ...newMedication, name: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-4">
                        <input
                          type="date"
                          className="form-control"
                          value={newMedication.startDate}
                          onChange={(e) =>
                            setNewMedication({ ...newMedication, startDate: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <button className="btn btn-primary btn-sm mb-2" onClick={addMedication}>
                      Add Medication
                    </button>
                    <ul className="list-group">
                      {medications.map((medication, index) => (
                        <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                          {`${medication.name} (${medication.startDate})`}
                          <button className="btn btn-danger btn-sm" onClick={() => removeMedication(index)}>
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Patient"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default EditPatient;
