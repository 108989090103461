import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import EmployeeTable from '../MUITable/EmployeeTable';
import ToastService from '../../Service/toast.service';

function EmployeeList() {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');

    const [employees, setEmployee] = useState([]);

    const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const handleDialogueOpen = () => {
        setErrorDialogueBoxOpen(true)
    };
    const handleDialogueClose = () => {
        setErrorList([]);
        setErrorDialogueBoxOpen(false)
    };

    useEffect(() => {
        getEmployees();
    }, []
    );

    const getEmployees = async () => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

        const response = await axios.get(`${baseApi}/employees`, {
            params: {
                name: name
            }
        });
        setEmployee(response.data);
    };

    const deleteEmployee = async (id) => {
        const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    
        try {
            await axios.delete(`${baseApi}/employees/${id}`);
            ToastService.success("Employee deleted successfully.")
            getEmployees();
        } catch (error) {
            // Extract error messages for display
            const errors = [];
            if (error.response) {
                // The request was made, and the server responded with a status code
                if (error.response.data && typeof error.response.data === 'object') {
                    errors.push(error.response.data.message || "An error occurred."); // Customize based on your API's error structure
                } else {
                    errors.push("An error occurred while processing your request.");
                    ToastService.error("An error occurred while processing your request.")
                }
            } else if (error.request) {
                errors.push("No response from server. Please try again later.");
                ToastService.error("No response from server. Please try again later.")
            } else {
                errors.push(error.message || "An unexpected error occurred.");
                ToastService.error("An unexpected error occurred.")

            }
    
            setErrorList(errors);
            handleDialogueOpen();
        }
    };
    


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>

            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                            <h4 className="page-title fw-bold">Employee</h4>
                        </div>
                        <div className="col-sm-8 col-9 text-right m-b-20">
                            <Link to="/account/employees/add" className="btn btn-primary float-right btn-rounded">
                                <i className="fa fa-plus"></i> Add Employee
                            </Link>
                        </div>
                    </div>
                    <form action="/account/employees" name="userFilter" >
                        <div className="row filter-row">

                            <div className="col-sm-4 col-md-4">
                                <div className="form-floating">
                                    <input type="text" name="name" className="form-control" placeholder='Employee Name' />
                                    <label className="focus-label">Employee Name</label>
                                </div>
                            </div>

                            <div className="col-sm-4 col-md-4">
                                <button type="submit" className="btn btn-primary btn-block"> Search </button>
                            </div>
                        </div>
                    </form>
                    <EmployeeTable employeeList={employees} deleteEmployee={deleteEmployee} />
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Gender</th>
                                            <th>Address</th>
                                            <th className="text-right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employees.map((employee, index) => (
                                            <tr key={employee._id}>
                                                <td>{index + 1}</td>
                                                <td>{employee.userId.firstName} {employee.userId.lastName}</td>
                                                <td>{employee.userId.email}</td>
                                                <td>{employee.phone}</td>
                                                <td>{employee.gender}</td>
                                                <td>{employee.address}</td>
                                                <td>
                                                    <Link
                                                        to={`/employees/edit/${employee._id}`}
                                                        className="btn btn-warning is-info is-small m-r-2"
                                                    >
                                                        <i className="fa fa-pencil m-r-5"></i> Edit
                                                    </Link>
                                                    <button
                                                        onClick={() => deleteEmployee(employee._id)}
                                                        className="btn btn-danger is-danger is-small m-l-5"
                                                    >
                                                        <i className="fa fa-trash-o m-r-5"></i>  Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                </div>
                <ErrorDialogueBox
                    open={errorDialogueBoxOpen}
                    handleToClose={handleDialogueClose}
                    ErrorTitle="Error: Add Employee"
                    ErrorList={errorList}
                />
            </div>

        </Box>
    )
}

export default EmployeeList;
