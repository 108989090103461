import React, { useEffect } from 'react';
import { gapi } from 'gapi-script';

const CLIENT_ID = `${process.env.REACT_APP_GCC_CLIENT_ID}`;
const API_KEY = `${process.env.REACT_APP_GCC_API_KEY}`;
const SCOPES = 'https://www.googleapis.com/auth/calendar';

const GoogleCalendarIntegration = ({ onCalendarSync }) => {
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            });
        };

        gapi.load('client:auth2', initClient);
    }, []);

    const handleAuthClick = async () => {
        const auth = gapi.auth2.getAuthInstance();
        await auth.signIn();

        const token = auth.currentUser.get().getAuthResponse().access_token;
        gapi.client.setToken({ access_token: token });

        const events = await fetchCalendarEvents();
        if (onCalendarSync) {
            onCalendarSync(events);
        }
    };

    const fetchCalendarEvents = async () => {
        try {
            const response = await gapi.client.calendar.events.list({
                calendarId: 'primary',
                timeMin: new Date().toISOString(),
                showDeleted: false,
                singleEvents: true,
                maxResults: 10,
                orderBy: 'startTime',
            });

            return response.result.items;
        } catch (error) {
            console.error('Error fetching calendar events', error);
            return [];
        }
    };

    return (
        <div>
            <button onClick={handleAuthClick} className="btn btn-primary">
                Connect Google Calendar
            </button>
        </div>
    );
};

export default GoogleCalendarIntegration;
