import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service';

function Addproduct() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [company, setCompany] = useState('');

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true)
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false)
  };


  const addproduct = (event) => {
    event.preventDefault();
    // TODO: Handle product form submission
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const form = document.forms.addproductForm;
    let product = {
      name: form.name.value,
      description: form.description.value,
      price: form.price.value,
      company: form.company.value
    }


    fetch(`${baseApi}/products`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(product)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let respMessage = data.message;
        if (respMessage === "success") {
          ToastService.success("Product added successfully.")
          navigate("/account/products");
        }
        else {
          //Display error message
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      });
  };



  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">

          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title">Add Product</h4>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-12">
                <form id="addproductForm" name='addproductForm' onSubmit={addproduct}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Brand <span className="text-danger">*</span></label>
                        <input name="company" className="form-control" type="text" required value={company} onChange={(event) => setCompany(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Product Name <span className="text-danger">*</span></label>
                        <input name="name" className="form-control" type="text" required value={name} onChange={(event) => setName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Description</label>
                        <input name="description" className="form-control" type="text" value={description} onChange={(event) => setDescription(event.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Price <span className="text-danger">*</span></label>
                        <input name="price" className="form-control" type="number" required value={price} onChange={(event) => setPrice(event.target.value)} />
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-left">
                    <button id="addproduct" type="submit" className="btn btn-primary submit-btn">Create product</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Product"
          ErrorList={errorList}
        />
      </div>
    </Box>
  )
}

export default Addproduct;
