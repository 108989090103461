import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service'

function AddPatient() {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [preferredComms, setPreferredComms] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [newCondition, setNewCondition] = useState('');
  const [medications, setMedications] = useState([]);
  const [newMedication, setNewMedication] = useState([])

  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };

  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const addCondition = () => {
    if (newCondition.trim() !== '') {
      setConditions([...conditions, newCondition.trim()]);
      setNewCondition('');
    }
  };

  const removeCondition = (index) => {
    const updatedConditions = conditions.filter((_, i) => i !== index);
    setConditions(updatedConditions);
  };

  const addMedication = () => {
    if (newMedication.name.trim() !== '' && newMedication.startDate !== '') {
      setMedications([...medications, newMedication]);
      setNewMedication({ name: '', startDate: '', inUse: false });
    }
  };
  
  const removeMedication = (index) => {
    const updatedMedications = medications.filter((_, i) => i !== index);
    setMedications(updatedMedications);
  };

  const addPatient = (event) => {
    event.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    // TODO: Handle patient form submission
    const form = document.forms.addPatientForm;
    let patient = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      idNumber: form.idNumber.value,
      preferredComms: form.preferredComms.value,
      username: form.username.value,
      email: form.email.value,
      phone: form.phone.value,
      password: form.password.value,
      confirmPassword: form.confirmPassword.value,
      address: form.address.value,
      gender: form.gender.value,
      dob: form.dob.value,
      conditions: conditions,
      medications: medications,
    };

    fetch(`${baseApi}/patients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        let respMessage = data.message;
        if (respMessage === 'success') {
          ToastService.success("Patient added successfully.")
          navigate('/account/patients');
        } else {
          // Display error message
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      });
  };

  useEffect(() => {
    if (password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-8 text-left">
                <h4 className="page-title">Add Patient</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <form id="addPatientForm" name="addPatientForm" onSubmit={addPatient}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input
                          name="firstName"
                          className="form-control"
                          type="text"
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input
                          name="lastName"
                          className="form-control"
                          type="text"
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input
                          name="username"
                          className="form-control"
                          type="text"
                          value={username}
                          onChange={(event) => setUsername(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input
                          name="email"
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone </label>
                        <input
                          name="phone"
                          className="form-control"
                          type="text"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Preferred Communication </label>
                        <input
                          name="preferredComms"
                          className="form-control"
                          type="text"
                          value={preferredComms}
                          onChange={(event) => setPreferredComms(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          name="password"
                          className="form-control"
                          type="password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input
                          name="confirmPassword"
                          className="form-control"
                          type="password"
                          value={confirmPassword}
                          onChange={(event) => setConfirmPassword(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Address </label>
                        <input
                          name="address"
                          className="form-control"
                          type="text"
                          value={address}
                          onChange={(event) => setAddress(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Gender</label>
                        <select
                          name="gender"
                          className="form-select"
                          value={gender}
                          onChange={(event) => setGender(event.target.value)}                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Date of Birth </label>
                        <input
                          name="dob"
                          className="form-control"
                          type="date"
                          value={dob}
                          onChange={(event) => setDOB(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Id Number</label>
                        <input
                          name="idNumber"
                          className="form-control"
                          type="number"
                          value={idNumber}
                          onChange={(event) => setIdNumber(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-left">
                    <button
                      id="addPatient"
                      type="submit"
                      className="btn btn-primary submit-btn"
                    >
                      Create Patient
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <h4>Pre-existing Conditions</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Add Condition</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Condition name"
                          value={newCondition}
                          onChange={(e) => setNewCondition(e.target.value)}
                        />
                        <button
                          className="btn btn-primary"
                          onClick={addCondition}
                          type="button"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <ul className="list-group mt-3">
                      {conditions.map((condition, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {condition}
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => removeCondition(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="card mt-4">
                  <div className="card-header">
                    <h4>Current Medications</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Medicine Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Medicine name"
                        value={newMedication.name}
                        onChange={(e) =>
                          setNewMedication({ ...newMedication, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={newMedication.startDate}
                        onChange={(e) =>
                          setNewMedication({ ...newMedication, startDate: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={newMedication.inUse}
                          onChange={(e) =>
                            setNewMedication({ ...newMedication, inUse: e.target.checked })
                          }
                        />{' '}
                        Currently in use
                      </label>
                    </div>
                    <button className="btn btn-primary" onClick={addMedication} type="button">
                      Add Medication
                    </button>
                    <ul className="list-group mt-3">
                      {medications.map((medication, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <strong>{medication.name}</strong> -{' '}
                            {new Date(medication.startDate).toLocaleDateString()} -{' '}
                            {medication.inUse ? 'In Use' : 'Not in Use'}
                          </div>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => removeMedication(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Patient"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default AddPatient;
