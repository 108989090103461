import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import axios from "axios";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function Editdoctor() {
  const navigate = useNavigate();

  const [value, setValue] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [department, setDepartment] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [qualifications, setQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState({ credential: '', university: '', year: '' });
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('');

  const [bookings, setBookings] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const { id } = useParams();

  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true);
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false);
  };

  const handleTabChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    getDoctorById();
  }, []);

  const getDoctorById = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    const response = await axios.get(`${baseApi}/doctors/${id}`);
    const data = response.data;

    setFirstName(data.userId.firstName);
    setLastName(data.userId.lastName);
    setEmail(data.userId.email);
    setUsername(data.userId.username);
    setPassword(data.userId.password);
    setConfirmPassword(data.userId.password);
    setPhone(data.phone);
    setDepartment(data.department);
    setUserId(data.userId._id);
    setQualifications(data.qualifications || []);
  };

  const updateDoctor = async (e) => {
    e.preventDefault();
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';

    try {
      await axios.patch(`${baseApi}/doctors/${id}`, {
        firstName,
        lastName,
        username,
        email,
        phone,
        password,
        confirmPassword,
        department,
        userId,
        qualifications,
      });
      navigate("/account/doctors");
    } catch (error) {
      console.log(error.response.data.errors);
      setErrorList(error.response.data.errors);
      handleDialogueOpen();
    }
  };

  const addQualification = () => {
    if (newQualification.credential && newQualification.university && newQualification.year) {
      setQualifications([...qualifications, newQualification]);
      setNewQualification({ credential: '', university: '', year: '' });
    }
  };

  const removeQualification = (index) => {
    setQualifications(qualifications.filter((_, i) => i !== index));
  };


  const fetchBookings = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/doctors/${id}/bookings`);
    setBookings(response.data.bookings || []);
  };

  const fetchActivityLog = async () => {
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const response = await axios.get(`${baseApi}/doctors/${id}/activity`);
    setActivityLog(response.data.activity || []);
  };

  useEffect(() => {
    if (password.length > 0 && password.trim().length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    } else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    } else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <Tabs value={value} onChange={handleTabChange} aria-label="Doctor Tabs">
              <Tab label="Primary Details" />
              <Tab label="Bookings" />
              <Tab label="Activity" />
            </Tabs>
            <div className="row">
              <div className="col-lg-8">
                <TabPanel value={value} index={0}>
                  <h3 className="page-title">Edit Doctor</h3>
                  <form id="editDoctorForm" name="editDoctorForm" onSubmit={updateDoctor}>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>First Name <span className="text-danger">*</span></label>
                          <input
                            name="firstName"
                            className="form-control"
                            type="text"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            name="lastName"
                            className="form-control"
                            type="text"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Username <span className="text-danger">*</span></label>
                          <input
                            name="username"
                            className="form-control"
                            type="text"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Email <span className="text-danger">*</span></label>
                          <input
                            name="email"
                            className="form-control"
                            type="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Password</label>
                          <input
                            name="password"
                            className="form-control"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Confirm Password</label>
                          <input
                            name="confirmPassword"
                            className="form-control"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Phone</label>
                          <input
                            name="phone"
                            className="form-control"
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Department</label>
                          <select
                            name="department"
                            className="form-select"
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                          >
                            <option value="General Medicine">General Medicine</option>
                            <option value="Mental Health">Mental Health</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="m-t-20 text-left">
                      <button type="submit" className="btn btn-primary submit-btn">
                        Update Doctor
                      </button>
                    </div>
                  </form>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <h3>Bookings</h3>
                  {bookings.length > 0 ? (
                    <ul>
                      {bookings.map((booking, index) => (
                        <li key={index}>
                          {booking.date} - {booking.status}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No bookings found.</p>
                  )}
                </TabPanel>

                {/* Activity */}
                <TabPanel value={value} index={2}>
                  <h3>Activity</h3>
                  {activityLog.length > 0 ? (
                    <ul>
                      {activityLog.map((activity, index) => (
                        <li key={index}>{activity}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No activity recorded.</p>
                  )}
                </TabPanel>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <h4>Qualifications</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Credential</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Credential"
                        value={newQualification.credential}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, credential: e.target.value })
                        }
                      />
                      <label>University</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="University"
                        value={newQualification.university}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, university: e.target.value })
                        }
                      />
                      <label>Year</label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Year"
                        value={newQualification.year}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, year: e.target.value })
                        }
                      />
                      <button className="btn btn-primary btn-sm" onClick={addQualification}>
                        Add Qualification
                      </button>
                      <ul className="list-group mt-3">
                        {qualifications.map((qualification, index) => (
                          <li
                            key={index}
                            className="list-group-item d-flex justify-content-between align-items-center"
                          >
                            {`${qualification.credential}, ${qualification.university} (${qualification.year})`}
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => removeQualification(index)}
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Edit Doctor"
          ErrorList={errorList}
        />
      </div>
    </Box>
  );
}

export default Editdoctor;
