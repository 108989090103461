import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import { GooglePlacesAutocomplete } from '../Service/googlePlacesAutocomplete';
import { Container, Button, Table } from '@mui/material';
// import { useNotification } from '../hooks/useNotification';

const CanvasLayoutCheckout = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sameAsPhysical, setSameAsPhysical] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    surname: '',
    email: '',
    mobile: '',
    physicalAddress: '',
    billingAddress: '',
  });

  const steps = ['Product Selection', 'Billing Details', 'Summary'];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/products');
        setProducts(response.data);
      } catch (error) {
        console.log('Error fetching products');
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (sameAsPhysical) {
      setBillingDetails(prev => ({
        ...prev,
        billingAddress: prev.physicalAddress
      }));
    }
  }, [sameAsPhysical, billingDetails.physicalAddress]);

  const handleNext = () => {
    if (activeStep === 0 && !selectedProduct) {
      console.log('Please select a package');
      return;
    }
    if (activeStep === 1) {
      const requiredFields = ['name', 'surname', 'email', 'mobile', 'physicalAddress', 'billingAddress'];
      const missingFields = requiredFields.filter(field => !billingDetails[field]);
      if (missingFields.length > 0) {
        console.log('Please fill in all required fields');
        return;
      }
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleFinish = async () => {
    try {
      const response = await axios.post('/payments/payfast/create', {
        product: selectedProduct,
        billingDetails
      });
      window.location.href = response.data.paymentUrl;
    } catch (error) {
      console.log('Payment initialization failed');
    }
  };

  const handleInputChange = (field) => (event) => {
    setBillingDetails(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Container>
            <Typography variant="h5" mb={2}>Select a Package</Typography>
            <Grid container spacing={2}>
              {products.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <Paper 
                    elevation={3} 
                    sx={{ 
                      padding: 2, 
                      textAlign: 'center',
                      border: selectedProduct?.id === product.id ? '2px solid #1976d2' : 'none'
                    }}
                  >
                    <Typography variant="h6">{product.name}</Typography>
                    <Typography variant="body2">{product.description}</Typography>
                    <Typography variant="body1" mt={1}>
                      R{product.price} / {product.billingFrequency}
                    </Typography>
                    <Button 
                      variant={selectedProduct?.id === product.id ? 'contained' : 'outlined'}
                      onClick={() => setSelectedProduct(product)}
                      className="mt-4"
                    >
                      Select
                    </Button>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        );

      case 1:
        return (
          <Container>
            <Typography variant="h5" mb={2}>Billing Details</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Name" 
                  fullWidth 
                  value={billingDetails.name}
                  onChange={handleInputChange('name')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Surname" 
                  fullWidth 
                  value={billingDetails.surname}
                  onChange={handleInputChange('surname')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Email" 
                  fullWidth 
                  type="email"
                  value={billingDetails.email}
                  onChange={handleInputChange('email')}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField 
                  label="Mobile Number" 
                  fullWidth 
                  value={billingDetails.mobile}
                  onChange={handleInputChange('mobile')}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GooglePlacesAutocomplete
                  label="Physical Address"
                  value={billingDetails.physicalAddress}
                  onChange={(address) => setBillingDetails(prev => ({
                    ...prev,
                    physicalAddress: address
                  }))}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sameAsPhysical}
                      onChange={(e) => setSameAsPhysical(e.target.checked)}
                    />
                  }
                  label="Billing address same as physical address"
                />
                {!sameAsPhysical && (
                  <GooglePlacesAutocomplete
                    label="Billing Address"
                    value={billingDetails.billingAddress}
                    onChange={(address) => setBillingDetails(prev => ({
                      ...prev,
                      billingAddress: address
                    }))}
                    required
                  />
                )}
              </Grid>
            </Grid>
            <Button
              variant="outlined"
              onClick={() => setShowLoginModal(true)}
              className="mt-4"
            >
              Login to Auto-Fill
            </Button>
          </Container>
        );

      case 2:
        return (
          <Container>
            <Typography variant="h5" mb={2}>Order Summary</Typography>
            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" mb={2}>Customer Details</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography><strong>Name:</strong> {billingDetails.name} {billingDetails.surname}</Typography>
                  <Typography><strong>Email:</strong> {billingDetails.email}</Typography>
                  <Typography><strong>Mobile:</strong> {billingDetails.mobile}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography><strong>Physical Address:</strong> {billingDetails.physicalAddress}</Typography>
                  <Typography><strong>Billing Address:</strong> {billingDetails.billingAddress}</Typography>
                </Grid>
              </Grid>
            </Paper>
            
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.Cell>Package</Table.Cell>
                  <Table.Cell>Description</Table.Cell>
                  <Table.Cell align="right">Price</Table.Cell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{selectedProduct?.name}</Table.Cell>
                  <Table.Cell>{selectedProduct?.description}</Table.Cell>
                  <Table.Cell align="right">R{selectedProduct?.price}</Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={2}><strong>Total</strong></Table.Cell>
                  <Table.Cell align="right"><strong>R{selectedProduct?.price}</strong></Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Container>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Box sx={{ padding: 4, margin: 'auto' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="wizard-container">
          {renderStepContent(activeStep)}
          <div className="button-container mt-4 flex justify-end gap-4">
            <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={activeStep === steps.length - 1 ? handleFinish : handleNext}
            >
              {activeStep === steps.length - 1 ? 'Proceed to Payment' : 'Next'}
            </Button>
          </div>
        </div>

        <Dialog open={showLoginModal} onClose={() => setShowLoginModal(false)}>
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
            <TextField
              label="Email"
              fullWidth
              className="mb-4 mt-4"
            />
            <TextField
              label="Password"
              type="password"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => setShowLoginModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => { 
                // Implement login logic
                setShowLoginModal(false);
              }}
            >
              Login
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default CanvasLayoutCheckout;