import React, { useState, useContext } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { ThemeContext } from "../../Context/ThemeContext";

const currencies = ["USD", "EUR", "GBP", "INR", "AUD", "CAD"];

export default function SettingsPage() {
  const { themeSettings, setThemeSettings } = useContext(ThemeContext);
  const [localSettings, setLocalSettings] = useState(themeSettings);
  const [tabIndex, setTabIndex] = useState(0);
  const [settings, setSettings] = useState({
    primaryColor: "#1976d2",
    secondaryColor: "#ff5722",
    tertiaryColor: "#673ab7",
    fontColor: "#000000",
    googleMapsApiKey: "",
    paypalClientId: "",
    paypalClientSecret: "",
    currency: "USD",
    storeDetails: {
      storeName: "",
      contactNumber: "",
      emailAddress: "",
      physicalAddress: "",
      webAddress: "",
      logo: "",
    },
    operatingHours: "",
    taxRate: 0,
    isTaxInclusive: true,
  });

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleInputChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStoreDetailsChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      storeDetails: {
        ...prev.storeDetails,
        [field]: value,
      },
    }));
  };

  const handleSubmit = () => {
    setThemeSettings(localSettings);
    console.log("Settings Saved:", settings);
    alert("Settings saved successfully!");
  };

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div>
            <h4 className="form-section-header mt-3">Theme Colour</h4>
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group">
                  <label>Primary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.primaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        primaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Secondary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.secondaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        secondaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Tertiary Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.tertiaryColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        tertiaryColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Font Color:</label>
                  <input
                    type="color"
                    className="form-control form-control-color"
                    value={localSettings.fontColor}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        fontColor: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            {/* Font Selection Section */}
            <h4 className="form-section-header mt-4">Font Settings</h4>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Primary Font:</label>
                  <select
                    className="form-control"
                    value={localSettings.primaryFont}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        primaryFont: e.target.value,
                      }))
                    }
                  >
                    <option value="Roboto">Roboto</option>
                    <option value="Arial">Arial</option>
                    <option value="Lato">Lato</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Open Sans">Open Sans</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Body Font:</label>
                  <select
                    className="form-control"
                    value={localSettings.bodyFont}
                    onChange={(e) =>
                      setLocalSettings((prev) => ({
                        ...prev,
                        bodyFont: e.target.value,
                      }))
                    }
                  >
                    <option value="Roboto">Roboto</option>
                    <option value="Arial">Arial</option>
                    <option value="Lato">Lato</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Open Sans">Open Sans</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <h4 className="form-section-header mt-3">Store Details</h4>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Store Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.storeName}
                    onChange={(e) =>
                      handleStoreDetailsChange("storeName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Contact Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.contactNumber}
                    onChange={(e) =>
                      handleStoreDetailsChange("contactNumber", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.storeDetails.emailAddress}
                    onChange={(e) =>
                      handleStoreDetailsChange("emailAddress", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h4 className="form-section-header mt-3">Integrations</h4>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Google Maps API Key</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.googleMapsApiKey}
                    onChange={(e) =>
                      handleInputChange("googleMapsApiKey", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>PayPal API Client ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={settings.paypalClientId}
                    onChange={(e) =>
                      handleInputChange("paypalClientId", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <Typography variant="h5" sx={{ marginBottom: 3, fontWeight: "bold" }}>
              Settings
            </Typography>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Theme Settings" />
              <Tab label="Store Settings" />
              <Tab label="Integrations" />
            </Tabs>
            <form id="settingsForm" name="settingsForm" onSubmit={handleSubmit}>
              {renderTabContent()}
              <div className="m-t-20 text-left">
                <button
                  id="saveSettings"
                  type="button"
                  className="btn btn-primary submit-btn"
                  onClick={handleSubmit}
                >
                  Save Settings
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Box>
  );
}
