import React from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { TextField } from '@mui/material';

export const GooglePlacesAutocomplete = ({ label, value, onChange, required }) => {
  const [autocomplete, setAutocomplete] = React.useState(null);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      onChange(place.formatted_address);
    }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
    >
      <TextField
        label={label}
        fullWidth
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
      />
    </Autocomplete>
  );
};