// src/theme.js
import { createTheme } from "@mui/material/styles";

const createCustomTheme = (settings) => {
  return createTheme({
    palette: {
      primary: {
        main: settings.primaryColor || "#1976d2",
      },
      secondary: {
        main: settings.secondaryColor || "#ff5722",
      },
      tertiary: {
        main: settings.tertiaryColor || "#673ab7", // Add tertiary as a custom key if needed
      },
      text: {
        primary: settings.fontColor || "#000000",
      },
    },
    typography: {
      fontFamily: "'Roboto', 'Arial', sans-serif",
    },
  });
};

export default createCustomTheme;
