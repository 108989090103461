import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@mui/material";

const currencies = ["USD", "EUR", "GBP", "INR", "AUD", "CAD"];

export default function CorporateSettingsPage() {
  const [settings, setSettings] = useState({
    primaryColor: "#1976d2",
    secondaryColor: "#ff5722",
    tertiaryColor: "#673ab7",
    fontColor: "#000000",
    googleMapsApiKey: "",
    paypalClientId: "",
    paypalClientSecret: "",
    currency: "USD",
    storeDetails: {
      storeName: "",
      contactNumber: "",
      emailAddress: "",
      physicalAddress: "",
      webAddress: "",
      logo: "",
    },
    operatingHours: "",
    taxRate: 0,
    isTaxInclusive: true,
  });

  const handleInputChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleStoreDetailsChange = (field, value) => {
    setSettings((prev) => ({
      ...prev,
      storeDetails: {
        ...prev.storeDetails,
        [field]: value,
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Settings Saved:", settings);
    alert("Settings saved successfully!");
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">
          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title fw-bold">Settings</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <form id="settingsForm" name="settingsForm" onSubmit={handleSubmit}>
                  <div className="row">
                    {/* Colours Section */}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Primary Colour</label>
                        <input
                          type="color"
                          className="form-control"
                          value={settings.primaryColor}
                          onChange={(e) => handleInputChange("primaryColor", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Secondary Colour</label>
                        <input
                          type="color"
                          className="form-control"
                          value={settings.secondaryColor}
                          onChange={(e) => handleInputChange("secondaryColor", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Tertiary Colour</label>
                        <input
                          type="color"
                          className="form-control"
                          value={settings.tertiaryColor}
                          onChange={(e) => handleInputChange("tertiaryColor", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Font Colour</label>
                        <input
                          type="color"
                          className="form-control"
                          value={settings.fontColor}
                          onChange={(e) => handleInputChange("fontColor", e.target.value)}
                        />
                      </div>
                    </div>

                    {/* API Keys Section */}
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Google Maps API Key</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.googleMapsApiKey}
                          onChange={(e) => handleInputChange("googleMapsApiKey", e.target.value)}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>PayPal API Client ID</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.paypalClientId}
                          onChange={(e) => handleInputChange("paypalClientId", e.target.value)}
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>PayPal API Client Secret</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.paypalClientSecret}
                          onChange={(e) => handleInputChange("paypalClientSecret", e.target.value)}
                        />
                      </div>
                    </div> */}

                    {/* Currency & Tax Section */}
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Currency</label>
                        <select
                          className="form-control"
                          value={settings.currency}
                          onChange={(e) => handleInputChange("currency", e.target.value)}
                        >
                          {currencies.map((currency) => (
                            <option key={currency} value={currency}>
                              {currency}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Tax Rate (%)</label>
                        <input
                          type="number"
                          className="form-control"
                          value={settings.taxRate}
                          onChange={(e) => handleInputChange("taxRate", e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={settings.isTaxInclusive}
                              onChange={(e) =>
                                handleInputChange("isTaxInclusive", e.target.checked)
                              }
                            />
                          }
                          label="Tax Inclusive"
                        />
                      </div>
                    </div> */}

                    {/* Store Details Section */}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Store Name</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.storeName}
                          onChange={(e) =>
                            handleStoreDetailsChange("storeName", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Contact Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.contactNumber}
                          onChange={(e) =>
                            handleStoreDetailsChange("contactNumber", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.emailAddress}
                          onChange={(e) =>
                            handleStoreDetailsChange("emailAddress", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Physical Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.physicalAddress}
                          onChange={(e) =>
                            handleStoreDetailsChange("physicalAddress", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Web Address</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.webAddress}
                          onChange={(e) =>
                            handleStoreDetailsChange("webAddress", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Logo URL</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.storeDetails.logo}
                          onChange={(e) =>
                            handleStoreDetailsChange("logo", e.target.value)
                          }
                        />
                      </div>
                    </div>

                    {/* Operating Hours Section */}
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Operating Hours</label>
                        <input
                          type="text"
                          className="form-control"
                          value={settings.operatingHours}
                          onChange={(e) => handleInputChange("operatingHours", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-left">
                    <button
                      id="saveSettings"
                      type="button"
                      className="btn btn-primary submit-btn"
                      onClick={handleSubmit}
                    >
                      Save Settings
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
