// src/ThemeContext.js
import React, { createContext, useState } from "react";
import createCustomTheme from "../theme";

const ThemeContext = createContext();

const ThemeProviderWrapper = ({ children }) => {
  const [themeSettings, setThemeSettings] = useState({
    primaryColor: "#1976d2",
    secondaryColor: "#ff5722",
    tertiaryColor: "#673ab7",
    fontColor: "#000000",
  });

  const theme = createCustomTheme(themeSettings);

  return (
    <ThemeContext.Provider value={{ themeSettings, setThemeSettings }}>
      {children(theme)}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProviderWrapper };
