import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Slide from '@mui/material/Slide';

// Transition element for the Dialogue Box
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ErrorDialogueBox(props) {
    // Ensure ErrorList is always an array
    const errorList = props.ErrorList || [];

    return (
        <Dialog
            open={props.open}
            onClose={props.handleToClose}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{ sx: { minWidth: "20%" } }}
        >
            <DialogTitle>{props.ErrorTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {errorList.length > 0 ? (
                        errorList.map((err, index) => (
                            <p className="text-center" key={index}>{err}</p>
                        ))
                    ) : (
                        <div>No error details available.</div>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleToClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialogueBox;
