import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';
import ToastService from '../../Service/toast.service'

function Adddoctor() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [department, setDepartment] = useState('');
  const [passwordMatchDisplay, setPasswordMatchDisplay] = useState('none');
  const [passwordValidationMessage, setPasswordValidationMessage] = useState('')
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState({
    credential: '',
    university: '',
    qualification: '',
    year: '',
  });
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true)
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false)
  };

  const adddoctor = (event) => {
    event.preventDefault();
    // TODO: Handle doctor form submission'
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const form = document.forms.adddoctorForm;
    let doctor = {
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      username: form.username.value,
      email: form.email.value,
      phone: form.phone.value,
      password: form.password.value,
      confirmPassword: form.confirmPassword.value,
      department: form.department.value,
      qualifications: qualifications,
    }

    fetch(`${baseApi}/doctors`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(doctor)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let respMessage = data.message;
        if (respMessage === "success") {
          ToastService.success("Doctor added successfully.")
          navigate("/account/doctors");
        }
        else {
          //Display error message
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      });
  };

  useEffect(() => {
    if (password.length > 0 && password?.trim()?.length <= 6) {
      setPasswordValidationMessage('Password Length must be greater than 6 characters');
    }
    else {
      setPasswordValidationMessage('');
    }
    if (password === confirmPassword) {
      setPasswordMatchDisplay('none');
    }
    else {
      setPasswordMatchDisplay('block');
    }
  }, [password, confirmPassword])

  const addQualification = () => {
    if (
      newQualification.credential.trim() !== '' &&
      newQualification.university.trim() !== '' &&
      newQualification.qualification.trim() !== '' &&
      newQualification.year.trim() !== ''
    ) {
      setQualifications([...qualifications, newQualification]);
      setNewQualification({ credential: '', university: '', qualification: '', year: '' });
    }
  };

  const removeQualification = (index) => {
    const updatedQualifications = qualifications.filter((_, i) => i !== index);
    setQualifications(updatedQualifications);
  };


  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">

          <div className="card-box">
            <div className="row">
              <div className="col-lg-8">
                <h4 className="page-title">Add Doctor</h4>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-8">
                <form id="adddoctorForm" name='adddoctorForm' onSubmit={adddoctor}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>First Name <span className="text-danger">*</span></label>
                        <input name="firstName" className="form-control" type="text" required value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Last Name</label>
                        <input name="lastName" className="form-control" type="text" required value={lastName} onChange={(event) => setLastName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Username <span className="text-danger">*</span></label>
                        <input name="username" className="form-control" type="text" required value={username} onChange={(event) => setUsername(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input name="email" className="form-control" type="email" required value={email} onChange={(event) => setEmail(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Password</label>
                        <input name="password" className="form-control" type="password" required value={password} onChange={(event) => setPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input name="confirmPassword" className="form-control" type="password" required value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Phone </label>
                        <input name="phone" className="form-control" type="text" value={phone} onChange={(event) => setPhone(event.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Department</label>
                        <select name="department" className="form-select" value={department} onChange={(event) => setDepartment(event.target.value)}>
                          <option value="General Medicine">General Medicine</option>
                          <option value="Mental Health">Mental Health</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="m-t-20 text-left">
                    <button id="adddoctor" type="submit" className="btn btn-primary submit-btn">Create Doctor</button>
                  </div>
                </form>
              </div>

              <div className="col-lg-4 mt-4">
                <div className="card">
                  <div className="card-header">
                    <h4>Qualifications</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label>Credential</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Credential"
                        value={newQualification.credential}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, credential: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>University</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="University"
                        value={newQualification.university}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, university: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Qualification</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Qualification"
                        value={newQualification.qualification}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, qualification: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Year</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Year"
                        value={newQualification.year}
                        onChange={(e) =>
                          setNewQualification({ ...newQualification, year: e.target.value })
                        }
                      />
                    </div>
                    <button className="btn btn-primary mt-3" onClick={addQualification} type="button">
                      Add Qualification
                    </button>
                    <ul className="list-group mt-3">
                      {qualifications.map((qualification, index) => (
                        <li
                          key={index}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <strong>{qualification.credential}</strong>, {qualification.university} (
                            {qualification.year}) - {qualification.qualification}
                          </div>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => removeQualification(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add doctor"
          ErrorList={errorList}
        />
      </div>
    </Box>
  )
}

export default Adddoctor;
