/**
 * Page routes component
 * This component defines the public and protected routes
 * 
 * @license GPLv2
 * @author Enrico Leigh
 * @package DRSPortal
 * @since 0.1
 */

import { Routes, Route } from 'react-router-dom';
import React, { useContext } from 'react';

import LoginPage from './components/Login/Login'
import CorporateLogin from './components/Login/CorporateLogin';

//import SignupPage from './pages/SignupPage';
import SignupPage from './components/SignUp/SignupPage';
import CheckoutWizard from './pages/Checkout';

import Dashboard from './components/dashboard/Dashboard';
import AdminDashboard from './components/dashboard/AdminDashboard';
import CorporateDashboard from './components/dashboard/CorporateDashboard';

import AddUser from './components/User/AddUser3';
import UserList from "./components/User/UserList3";
import EditUser from "./components/User/EditUser3";
import User from './components/User/User';

import AddPatient from './components/Patient/AddPatient';
import PatientList from "./components/Patient/PatientList";
import EditPatient from "./components/Patient/EditPatient";
import Patient from './components/Patient/Patient';

import AddCustomer from './components/Customer/AddCustomer';
import CustomerList from "./components/Customer/CustomerList";
import EditCustomer from "./components/Customer/EditCustomer";
import Customers from './components/Customer/Customer';

import AddEmployee from './components/Employee/AddEmployee';
import EmployeeList from "./components/Employee/EmployeeList";
import EditEmployee from "./components/Employee/EditEmployee";
import Employee from './components/Employee/Employee';

import AddDoctor from './components/Doctor/AddDoctor';
import DoctorList from "./components/Doctor/DoctorList";
import EditDoctor from "./components/Doctor/EditDoctor";
import Doctor from './components/Doctor/Doctor';

import AddMedicine from './components/Medicine/AddMedicine';
import MedicineList from "./components/Medicine/MedicineList";
import EditMedicine from "./components/Medicine/EditMedicine";
import Medicine from './components/Medicine/Medicine';

import AddProduct from './components/Product/AddProduct';
import ProductList from "./components/Product/ProductList";
import EditProduct from "./components/Product/EditProduct";

import PrescriptionList from "./components/Prescription/PrescriptionList";
import AddPrescription from "./components/Prescription/AddPrescription";
import Prescription from './components/Prescription/Prescription';
import Success from './components/Prescription/Success';
import Cancel from './components/Prescription/Cancel';

import AdminReportingScreen from './components/Reports/AdminReports';
import CorporateUserReportingScreen from './components/Reports/CorporateReports';
import DoctorUserReportingScreen from './components/Reports/DoctorReports';

// import AddOrder from './components/Order/AddOrder';
import OrdersList from "./components/Order/OrderList";
// import EditOrder from "./components/Order/EditOrder";
import Orders from './components/Order/Order';

import TransactionList from './components/Transactions/TransactionList';
import Transaction from './components/Transactions/Transaction';
import AddTransaction from './components/Transactions/AddTransaction';
import Edittransaction from './components/Transactions/EditTransaction';

import { UserContext } from './Context/UserContext'
import PatientDashboard from './components/dashboard/PatientDashboard';
import CustomerDashboard from './components/dashboard/CustomerDashboard';
import EmployeeDashboard from './components/dashboard/EmployeeDashboard';
import DoctorDashboard from './components/dashboard/DoctorDashboard';

import AdminAppointment from './components/Appointment/AdminAppointment';
import PatientAppointment from './components/Appointment/PatientAppointment';
import DoctorAppointment from './components/Appointment/DoctorAppointment';
import CorporateAppointment from './components/Appointment/CorporateAppointment';

import DoctorProfile from './components/Profile/DoctorProfile';
import PatientProfile from './components/Profile/PatientProfile';
import CustomerProfile from './components/Profile/CustomerProfile';
import EmployeeProfile from './components/Profile/EmployeeProfile';
import AdminProfile from './components/Profile/AdminProfile';
import CorporateProfile from './components/Profile/CorporateProfile';

import Corporate from './components/Corporate/Corporate';
import CorporateList from './components/Corporate/Corporate3';
import AddCorporate from './components/Corporate/AddCorporate3';
import EditCorporate from './components/Corporate/EditCorporate3';
import ContactInfoScreen from './components/Corporate/ContactInfoScreen';

//import Order from './components/Order/Order';
import Products from './components/Product/Product';
//import Booking from './components/Booking/Bookings';

import SettingsPage from './components/System/SettingsPage';
import CorporateSettingsPage from './components/System/CorporateSettings';

import Login from './components/Login/Login';
import PatientHistory from './components/Patient/PatientHistory';

/**
 * Constant to return Not Found path message
 * @since 0.1
 * @returns 
 */
const NotFound = () => <h2 style={{ margin: '70px' }}>This Path is not available</h2>

/**
 * Protected admin route function to return child object for Admin user role
 * @since 0.1
 * @param {Object} children 
 * @returns
 */
function ProtectedAdminRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Admin") {
        return children;
    }
}

/**
 * Protected corporate route function to return child object for Corporate user role
 * @since 0.1
 * @param {Object} children 
 * @returns
 */
function ProtectedCorporateRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Corporate") {
        return children;
    }
}

/**
 * Protected staff route function to return child object by staff user role
 * @param {Object} children 
 * @returns 
 */
function ProtectedStaffRoute({ children }) {
    const { currentUser } = useContext(UserContext);
    if (currentUser.userType == "Admin" || currentUser.userType == "Employee" || currentUser.userType == "Nurse" || currentUser.userType == "Doctor") {
        return children;
    }
}

/**
 * Page routes export function for defining page routes
 * @since 0.1
 * @returns Object
 */
export default function PageRoutes() {
    const { currentUser } = useContext(UserContext);
    return (
        <Routes>
            {/* Defines frontend login and transactional routes*/}
            <Route path="/" element={<LoginPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path='/checkout' element={<CheckoutWizard />} />

            {/* Defines public corporate routes */}
            <Route path="/corporate/:slug" element={<CorporateLogin />} />
            <Route path='/corporate/roadcover' element={<CorporateLogin />} />

            <Route path='/account' element={<Dashboard />} >

                {/* Define dashboards per user role */}
                <Route index element={
                    currentUser.userType == "Admin" ?
                        <AdminDashboard /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorDashboard /> :
                            currentUser.userType == "Patient" ?
                                <PatientDashboard /> :
                                currentUser.userType == "Employee" ?
                                    <EmployeeDashboard /> :
                                    currentUser.userType == "Customer" ?
                                        <CustomerDashboard /> :
                                        currentUser.userType == "Corporate" ?
                                            <CorporateDashboard /> :
                                            <div />}
                />

                {/* Defines protected user routes*/}
                <Route path='users' element={<ProtectedAdminRoute>  <User /> </ProtectedAdminRoute>} >
                    <Route index element={<UserList />} />
                    <Route path='add' element={<AddUser />} />
                    <Route path="edit/:id" element={<EditUser />} />
                </Route>

                {/* Defines protected corporate routes */}
                <Route path='corporates' element={<ProtectedAdminRoute>  <Corporate /> </ProtectedAdminRoute>} >
                    <Route index element={<CorporateList />} />
                    <Route path='add' element={<AddCorporate />} />
                    <Route path="edit/:id" element={<EditCorporate />} />
                </Route>

                {/* Defines protected patient routes*/}
                <Route path='patients' element={<ProtectedAdminRoute>  <Patient /> </ProtectedAdminRoute>} >
                    <Route index element={<PatientList />} />
                    <Route path='add' element={<AddPatient />} />
                    <Route path="edit/:id" element={<EditPatient />} />
                </Route>

                {/* Defines protected doctor routes*/}
                <Route path='doctors' element={<ProtectedAdminRoute>  <Doctor /> </ProtectedAdminRoute>} >
                    <Route index element={<DoctorList />} />
                    <Route path='add' element={<AddDoctor />} />
                    <Route path="edit/:id" element={<EditDoctor />} />
                </Route>

                {/* Defines protected medicine routes*/}
                <Route path='medicines' element={<Medicine />} >
                    <Route index element={<ProtectedStaffRoute> <MedicineList /> </ProtectedStaffRoute>} />
                    <Route path='add' element={<ProtectedAdminRoute> <AddMedicine /> </ProtectedAdminRoute>} />
                    <Route path="edit/:id" element={<ProtectedAdminRoute> <EditMedicine /></ProtectedAdminRoute>} />
                </Route>

                {/* Defines protected prescription routes*/}
                <Route path='prescriptions' element={<Prescription />} >
                    <Route index element={<PrescriptionList />} />
                    <Route path='add' element={<ProtectedAdminRoute> <AddPrescription /> </ProtectedAdminRoute>} />
                    <Route path="success" element={<Success />}></Route>
                    <Route path="cancel" element={<Cancel />}></Route>
                </Route>

                {/* Defines protected employee routes*/}
                <Route path='employee' element={<Employee />} >
                    <Route index element={<EmployeeList />} />
                    <Route path='add' element={<AddEmployee />} />
                    <Route path="edit/:id" element={<EditEmployee />} />
                </Route>

                {/* Defines protected customer routes*/}
                <Route path='customers' element={<Customers />} >
                    <Route index element={<CustomerList />} />
                    <Route path='add' element={<AddCustomer />} />
                    <Route path="edit/:id" element={<EditCustomer />} />
                </Route>

                {/* Defines protected product routes*/}
                <Route path='products' element={<Products />} >
                    <Route index element={<ProductList />} />
                    <Route path='add' element={<AddProduct />} />
                    <Route path="edit/:id" element={<EditProduct />} />
                </Route>

                {/* Defines protected product routes*/}
                <Route path='customer-check' element={<ContactInfoScreen />} >
                </Route>

                <Route path='orders' element= { <Orders />} >
                    <Route index element= {<OrdersList />} />
                </Route>

                <Route path='transactions' element={<ProtectedAdminRoute><Transaction /></ProtectedAdminRoute>} >
                    <Route index element={<TransactionList />} />
                    <Route path='add' element={<AddTransaction />} />
                    <Route path="edit/:id" element={<Edittransaction />} />
                </Route>

                {/* Defines protected patient history routes*/}
                <Route path='patient/history/:id' element={<ProtectedStaffRoute> <PatientHistory /> </ProtectedStaffRoute>} >

                </Route>

                {/* Defines appointments routes by user role */}
                <Route path='appointments' element={
                    currentUser.userType == "Admin" ?
                        <AdminAppointment /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorAppointment /> :
                            currentUser.userType == "Patient" ?
                                <PatientAppointment /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateAppointment /> :
                                    <div />}
                />

                <Route path='reports' element={
                    currentUser.userType == "Admin" ?
                        <AdminReportingScreen /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorUserReportingScreen /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateUserReportingScreen /> :
                                    <div />}
                     />

                {/* Defines protected settings routes*/}
                <Route path='settings' element={<ProtectedAdminRoute>  <SettingsPage /> </ProtectedAdminRoute>} />
                <Route path='corporate-settings' element={<ProtectedCorporateRoute>  <CorporateSettingsPage /> </ProtectedCorporateRoute>} />

                {/* Defines profile routes by user role */}
                <Route path='profile' element={
                    currentUser.userType == "Admin" ?
                        <AdminProfile /> :
                        currentUser.userType == "Doctor" ?
                            <DoctorProfile /> :
                            currentUser.userType == "Patient" ?
                                <PatientProfile /> :
                                currentUser.userType == "Corporate" ?
                                    <CorporateProfile /> :
                                    <div />
                }
                />

                {/* Defines doctor profile route */}
                {/* <Route path='/profile' element={<DoctorProfile />} /> */}

            </Route>

            {/* <Route path="/users" element={<UserList />} /> */}
            {/* <Route path="/users3" element={<UserList3 />} /> */}
            {/* <Route path='/adduser' element= {<AddUser />} />
            <Route path="edituser/:id" element={<EditUser />} /> */}

            {/* Defines error pages routes*/}
            <Route path='/*' element={<NotFound />} />

        </Routes>
    )
}