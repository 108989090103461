import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import ErrorDialogueBox from '../MUIDialogueBox/ErrorDialogueBox';
import Box from '@mui/material/Box';

function AddTransaction() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [company, setCompany] = useState('');
  const [indications, setIndications] = useState('');
  const [contraindications, setContraIndications] = useState('');
  const [errorDialogueBoxOpen, setErrorDialogueBoxOpen] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const handleDialogueOpen = () => {
    setErrorDialogueBoxOpen(true)
  };
  const handleDialogueClose = () => {
    setErrorList([]);
    setErrorDialogueBoxOpen(false)
  };


  const addtransaction = (event) => {
    event.preventDefault();
    // TODO: Handle transaction form submission
    const baseApi = process.env.REACT_APP_API_BASE_URL || 'https://my.docotela.co.za/api';
    const form = document.forms.addtransactionForm;
    let transaction = {
      icd10Code: form.icd10Code.value,
      name: form.name.value,
      description: form.description.value,
      price: form.price.value,
      company: form.company.value,
      indications: form.indications.value,
      contraindications: form.contraindications.value
    }


    fetch(`${baseApi}/transactions`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(transaction)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let respMessage = data.message;
        if (respMessage === "success") {
          navigate("/account/transactions");
        }
        else {
          //Display error message
          setErrorList(data.errors);
          handleDialogueOpen();
        }
      });
  };



  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="page-wrapper">
        <div className="content">

          <div className="card-box">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="page-title fw-bold">Add Transaction</h4>
              </div>
            </div>
            <div className="row">

              <div className="col-lg-12">
                <form id="addtransactionForm" name='addtransactionForm' onSubmit={addtransaction}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Brand <span className="text-danger">*</span></label>
                        <input name="company" className="form-control" type="text" required value={company} onChange={(event) => setCompany(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Transaction Name <span className="text-danger">*</span></label>
                        <input name="name" className="form-control" type="text" required value={name} onChange={(event) => setName(event.target.value)} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Description</label>
                        <input name="description" className="form-control" type="text" value={description} onChange={(event) => setDescription(event.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Price <span className="text-danger">*</span></label>
                        <input name="price" className="form-control" type="number" required value={price} onChange={(event) => setPrice(event.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Indications <span className="text-danger">*</span></label>
                        <textarea name="indications" className="form-control" type="number" required value={indications} onChange={(event) => setIndications(event.target.value)} />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Contra-indications <span className="text-danger">*</span></label>
                        <textarea name="contraindications" className="form-control" type="number" required value={contraindications} onChange={(event) => setContraIndications(event.target.value)} />
                      </div>
                    </div>
                    

                  </div>

                  <div className="m-t-20 text-left">
                    <button id="addtransaction" type="submit" className="btn btn-primary submit-btn">Create transaction</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ErrorDialogueBox
          open={errorDialogueBoxOpen}
          handleToClose={handleDialogueClose}
          ErrorTitle="Error: Add Transaction"
          ErrorList={errorList}
        />
      </div>
    </Box>
  )
}

export default AddTransaction;
